import React from "react";
// import aboutVideo from "../../Assets/aboutvideo.mp4";

function About() {
  return (
    // <div className="w-full h-full bg-[#d1e3d133]">
    //   <div className="w-11/12 md:w-10/12 mx-auto text-center px-10 py-20">
    //     <h2 className="text-4xl lg:text-6xl py-4 font-bold">Who We Are</h2>
    //     <p className="mt-6 font-Roboto text-[#34454c] md:text-2xl lg:text-xl text-2xl">
    //       At <span className="text-2xl font-medium">Orazweb Solution's</span>{" "}
    //       we're passionate about using the power of technology to transform
    //       businesses and bring your innovative ideas to life. Our team of
    //       experts combines creativity, technical expertise, and business acumen
    //       to deliver tailored solutions that meet the unique needs of our
    //       clients. With a portfolio of services spanning app development, web
    //       development, web design, and Shopify store fronts, we help
    //       entrepreneurs and organizations alike build a strong online presence,
    //       streamline their operations, and drive growth. Our commitment to
    //       excellence, customer satisfaction, and staying ahead of the curve
    //       enables us to help our clients thrive in today's fast-paced digital
    //       landscape.
    //     </p>
    //   </div>
    //   <div className="border w-11/12 md:w-10/12 mx-auto mt-4"></div>
    //   {/* video section */}
    //   <div className="w-11/12 md:w-10/12 mx-auto text-center px-10 py-20 flex flex-col md:flex-row items-center">
    //     <video
    //       src={aboutVideo}
    //       width="800"
    //       height="500"
    //       controls
    //       className="shadow-lg w-full h-full"
    //     />

    //     <p className="mt-6 md:text-2xl lg:text-xl text-2xl text-[#34454c] md:ml-6  h-full">
    //       At Orazweb, we're skilled at creating websites for any size project.
    //       Whether it's a big venture or a small one, we're here to assist you
    //       from start to finish. Feeling lost on where to begin? No worries!
    //       We'll guide you every step of the way.
    //     </p>
    //   </div>
    // </div>

    <section id="about" className="bg-muted py-12 md:py-24 lg:py-32">
      <div className="container px-4 md:px-6 space-y-8">
        <div className="text-center space-y-4">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
            Who Are We
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-4">
            <h3 className="text-3xl font-bold">Our Story</h3>
            <p className="text-muted-foreground text-xl">
              Oraz Web Solution ignited in 2024 with a burning desire to empower
              businesses of all shapes and sizes to dominate online. Our squad
              of passionate web developers, designers, and SEO wizards are
              dedicated to crafting custom, high-octane websites that propel
              your business towards success.
            </p>
            <p className="text-muted-foreground text-xl">
              Since our launch in 2024, we've set our sights on empowering
              businesses of all sizes to conquer the online world. We're eager
              to partner with forward-thinking companies and become their
              trusted web development ally.
            </p>
          </div>
          <div className="space-y-4">
            <h3 className="text-3xl font-bold">Our Mission</h3>
            <p className="text-muted-foreground text-xl">
              At{" "}
              <span className="text-2xl font-medium">Orazweb Solution's</span>{" "}
              we're passionate about using the power of technology to transform
              businesses and bring your innovative ideas to life. Our team of
              experts combines creativity, technical expertise, and business
              acumen to deliver tailored solutions that meet the unique needs of
              our clients.
            </p>
            <p className="text-muted-foreground text-xl">
              Whether you're looking to build a new website, improve your search
              engine optimization, or enhance your digital marketing strategy,
              our team is here to support you every step of the way. We're
              passionate about what we do, and we're committed to delivering
              exceptional results for our clients.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
