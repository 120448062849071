import React from "react";

function TandC() {
  return (
    <div>
      <div className="mt-20 flex w-11/12 md:w-10/12 mx-auto flex-wrap px-10 py-10">
        <section className="flex flex-col text-l font-normal">
          <h1 className="text-3xl font-medium py-4 text-center">
            Terms and Conditions
          </h1>

          <p>Welcome to Oraz Web Solution'.</p>

          <p>
            These terms and conditions ("Terms", "Terms and Conditions",
            "Agreement") outline the rules and regulations for your use of our
            Service operated by [Your Company Name] ("us", "we", or "our").
          </p>

          <section className="flex flex-col flex-wrap gap-4">
            <h2 className="text-3xl font-medium py-4 text-center">
              Acceptance of These Terms
            </h2>
            <p>
              By accessing or using the Service, you agree to be bound by these
              Terms. If you disagree with any part of the terms, you may not
              access or use the Service.
            </p>
          </section>

          <section className="flex flex-col flex-wrap gap-4">
            <h2 className="text-3xl font-medium py-4 text-center">
              Changes to These Terms
            </h2>
            <p>
              We may update our Terms and Conditions at any time by posting the
              revised terms on our Service. You are expected to check this page
              regularly so you are aware of any changes, as they are binding on
              you.
            </p>
            <p>
              Your continued use of the Service after we post revised Terms will
              mean you accept the changes.
            </p>
          </section>

          <section className="flex flex-col flex-wrap gap-4">
            <h2 className="text-3xl font-medium py-4 text-center">
              Information We Collect
            </h2>
            <p>
              We collect information you provide directly when you use our
              Service and information collected automatically. Refer to our
              Privacy Policy for details about the information we collect and
              how we use it.
            </p>
          </section>

          <section className="flex flex-col flex-wrap gap-4">
            <h2 className="text-3xl font-medium py-4 text-center">
              Intellectual Property
            </h2>
            <p>
              The Service and its original content, features, and functionality
              are and will remain the exclusive property of [Your Company Name]
              and its licensors. The Service is protected by copyright,
              trademark, and other intellectual property laws.
            </p>
          </section>

          <section className="flex flex-col flex-wrap gap-4">
            <h2 className="text-3xl font-medium py-4 text-center">
              Termination
            </h2>
            <p>
              We may terminate or suspend your access to the Service
              immediately, without prior notice or liability, for any reason
              whatsoever, including without limitation if you breach these
              Terms.
            </p>
          </section>

          <section className="flex flex-col flex-wrap gap-4">
            <h2 className="text-3xl font-medium py-4 text-center">
              Disclaimer
            </h2>
            <p>
              Your use of the Service is at your sole risk. The Service is
              provided on an "as is" and "as available" basis. The Service is
              provided without warranties of any kind, whether express or
              implied, including but not limited to implied warranties of
              merchantability, fitness for a particular purpose,
              non-infringement, or course of performance.
            </p>
          </section>

          <section className="flex flex-col flex-wrap gap-4">
            <h2 className="text-3xl font-medium py-4 text-center">
              Governing Law
            </h2>
            <p>
              These Terms shall be governed and construed in accordance with the
              laws without regard to its conflict of laws provisions.
            </p>
          </section>

          <section className="flex flex-col flex-wrap gap-4">
            <h2 className="text-3xl font-medium py-4 text-center">
              Contact Us
            </h2>
            <p>
              If you have any questions about these Terms, please contact us
              through <span className="cursor-pointer">team@orazweb.com</span>
            </p>
          </section>
        </section>
      </div>
    </div>
  );
}

export default TandC;
