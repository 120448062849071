import React from "react";
import Seo from "../Seo/Seo";
import IconCloud from "./IconCloud";

function Technology() {
  const seoData = {
    title: "Orazweb Solutions: Cutting-Edge Technologies We Use",
    description:
      "At Orazweb Solutions, we leverage the latest technologies to deliver exceptional web design, development, and app development services. Discover the innovative tools and frameworks we use to bring your vision to life.",
    keywords:
      "web design technologies, web development technologies, app development technologies, cutting-edge technology, innovation, web development frameworks, mobile app development frameworks",
  };
  const iconSlugs = [    'react', 'javascript', 'html5', 'css3', 'nodejs',
    'python', 'java', 'typescript', 'angular', 'vuejs',
    'mongodb', 'postgresql', 'mysql', 'docker', 'kubernetes',
    'aws', 'googlecloud', 'azure', 'git', 'github',
    'vscode', 'figma', 'adobephotoshop', 'slack', 'jira']; 
  return (
    <div className="w-full h-full bg-muted">
      <Seo {...seoData} />
      <div className="w-11/12 md:w-10/12 h-full mx-auto flex  justify-evenly items-center flex-col lg:flex-row px-10 py-20">
        <div className="border border-black text-center flex flex-col justify-center items-center min-h-80 sm:w-[75%] md:w-[75%] lg:w-[50%] rounded-md px-4 py-8">
          <h3 className="text-4xl md:text-3xl py-4 font-bold">
            Technologies We Work On
          </h3>
          <p className="md:text-2xl lg:text-xl text-2xl text-[#34454c] md:ml-6  h-full">
            Our expertise spans a wide range of technologies to craft
            cutting-edge web and mobile solutions.
          </p>
        </div>
        {/* tech icon part */}
        <div>
          <IconCloud iconSlugs={iconSlugs}/>
        </div>
      </div>
    </div>
  );
}

export default Technology;
