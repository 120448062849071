import React from "react";
import { FaInstagram } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import { HiMail, HiPhone } from "react-icons/hi";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

// import "./Footer.css";
function Footer() {
  return (
    <section className=" w-full h-full bg-muted">
      {/* footer content */}

      <div className="flex w-11/12 md:w-10/12 mx-auto flex-wrap justify-around px-10 py-20 gap-y-8">
        <section className="flex flex-col gap-y-4">
          <h3 className="text-2xl font-medium	py-2">Who We Are</h3>
          <p className="font-Roboto text-[#34454c] text-lg max-w-80">
            At Orazweb Solutions, we turn business ideas into reality with
            top-notch technology. Our team of creative problem solvers excels in
            building apps, websites, and Shopify stores. Let us help your
            business stand out and grow online.
          </p>

          <Link
            to="https://www.instagram.com/orazwebsolutions/"
            target="_blank"
          >
            <FaInstagram className="w-10 h-10" />
          </Link>
        </section>

        <section>
          <h3 className="text-xl font-medium	py-2">QUICK NAVIGATION</h3>

          <ul className="cursor-pointer gap-y-4">
            <li className="group p-2">
              <ScrollLink
                to="home"
                smooth={true}
                duration={500}
                className="flex items-center gap-x-2"
              >
                <MdKeyboardArrowRight />
                <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                  
                  HOME
                </Link>
              </ScrollLink>
            </li>
            <li className="group p-2">
              <ScrollLink
                to="about"
                smooth={true}
                duration={500}
                className="flex items-center gap-x-2"
              >
                <MdKeyboardArrowRight />
                <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                  
                  ABOUT
                </Link>
              </ScrollLink>
            </li>
            <li className="group p-2">
              <ScrollLink
                to="services"
                smooth={true}
                duration={500}
                className="flex items-center gap-x-2"
              >
                <MdKeyboardArrowRight />
                <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                  
                  SERVICES
                </Link>
              </ScrollLink>
            </li>
            <li className="group p-2">
              <ScrollLink
                to="technologies"
                smooth={true}
                duration={500}
                className="flex items-center gap-x-2"
              >
                <MdKeyboardArrowRight />
                <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                  
                  TECHNOLOGIES
                </Link>
              </ScrollLink>
            </li>
            <li className="group p-2">
              <ScrollLink
                to="contactUs"
                smooth={true}
                duration={500}
                className="flex items-center gap-x-2"
              >
                
                  <MdKeyboardArrowRight />
                  <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                  CONTACT US
                </Link>
              </ScrollLink>
            </li>
          </ul>
        </section>

        <section>
          <h3 className="text-xl font-medium	py-2">RESOURCES</h3>

          <ul className="cursor-pointer gap-y-4">
            {/* <li className="group p-2">
              <Link to="/Blogs" smooth={true} duration={500}>
                Blogs
              </Link>
            </li> */}
            <li className="group p-2">
              <Link to="/privacy-policy" className="flex items-center gap-x-2" onClick={() => window.scrollTo(0, 0)}>
                <MdKeyboardArrowRight />
                Privacy Policy
              </Link>
            </li>
            <li className="group p-2">
              <Link
                to="/terms-and-conditions"
                className="flex items-center gap-x-2" onClick={() => window.scrollTo(0, 0)}
              >
                <MdKeyboardArrowRight />
                Terms & Conditions
              </Link>
            </li>
            {/* <li className="group p-2">
              <Link
                to="refund-and-cancelation"
                className="flex items-center gap-x-2"
              >
                <MdKeyboardArrowRight />
                Refund and Cancellation
              </Link>
            </li> */}
          </ul>
        </section>

        <section className="flex flex-col gap-y-4">
          <h3 className="text-xl font-medium	py-2">Get In Touch</h3>

          <span className="flex items-center gap-x-2">
            <HiMail />
            <p>info@orazweb.com</p>
          </span>

          <span className="flex items-center gap-x-2">
            <HiPhone />
            <p>+91 979 760 2332</p>
          </span>

          <span className="flex items-center gap-x-2">
            <HiPhone />
            <p>+91 808 203 9003</p>
          </span>
        </section>
      </div>
      {/* copyright section */}
      <div className="flex w-11/12 md:w-10/12 mx-auto flex-wrap justify-around px-10 py-4">
        <p className="text-xl font-bold text-center">
          Copyright 2024 <span>&copy;</span> Orazweb Solutions
          <br />
          All rights reserved
        </p>
      </div>
    </section>
  );
}

export default Footer;
