import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Pages/Home/Home";
import { Route, Routes } from "react-router-dom";
import PrivacyPol from "./Pages/PrivacyPolicy/PrivacyPol";
import TandC from "./Pages/TermsAndConditions/TandC";
import Footer from "./Components/Footer/Footer";
// import { useLocation } from 'react-router-dom';
// import { useEffect } from "react";

// function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/privacy-policy" element={<PrivacyPol />} />
        <Route exact path="/terms-and-conditions" element={<TandC />} />
      </Routes>
      <Footer />
      
    </div>
  );
}

export default App;
