import React from "react";

function PrivacyPol() {
  return (
    <div>
      <div className="mt-20 flex w-11/12 md:w-10/12 mx-auto flex-wrap px-10 py-10">
        <section className="flex flex-col text-l font-normal">
          <h1 className="text-3xl font-medium py-4 text-center">
            Privacy Policy
          </h1>

          <section className="flex flex-col flex-wrap gap-4">
            <p>
              <span className="">Orazweb Solutions ("us", "we", or "our")</span>{" "}
              operates the{" "}
              <a href="https://orazweb.com/" className="text-blue-600">
                https://orazweb.com/
              </a>{" "}
              website
            </p>
            <p>
              This page informs you of our policies regarding the collection,
              use, and disclosure of personal data when you use our Service and
              the choices you have associated with that data.
            </p>
          </section>

          <section className="flex flex-col flex-wrap gap-4">
            <h2 className="text-3xl font-medium py-4 text-center">
              Information We Collect
            </h2>
            <p>
              We collect several different types of information for various
              purposes to improve our Service to you.
            </p>
            <ul>
              <li>
                <span className="text-xl">Personal data</span>
                <ul className="pl-4">
                  <li>Email address</li>
                  <li>First name and last name</li>
                  <li>Phone number</li>
                </ul>
              </li>
              <li>
                <span className="text-xl"> Non-Personal Data</span>
                <ul className="pl-4">
                  <li>
                    Technical data such as your IP address, browser type, and
                    device information.
                  </li>
                </ul>
              </li>
            </ul>
          </section>

          <section className="flex flex-col flex-wrap gap-4">
            <h2 className="text-3xl font-medium py-4 text-center">
              How We Use Your Information
            </h2>
            <p>We use the information we collect in various ways, including:</p>
            <ul className="pl-4">
              <li>To provide and maintain our Service</li>
              <li>To improve and personalize our Service</li>
              <li>To send you marketing and promotional materials</li>
              <li>To respond to your inquiries and requests</li>
              <li>For security and fraud prevention purposes</li>
            </ul>
          </section>

          <section className="flex flex-col flex-wrap gap-4">
            <h2 className="text-3xl font-medium py-4 text-center">
              Disclosure of Your Information
            </h2>
            <p>
              We may disclose your information under certain circumstances, such
              as:
            </p>
            <ul className="pl-4">
              <li>To comply with legal obligations</li>
              <li>To protect our rights and property</li>
              <li>
                To prevent or investigate possible wrongdoing in connection with
                our Service
              </li>
              <li>
                To protect the safety of users of our Service or the public
              </li>
              <li>
                To a third-party in the event of a merger, acquisition, or
                bankruptcy
              </li>
            </ul>
            <p>
              We will not share your personal information with any third-party
              companies for their marketing purposes without your consent.
            </p>
          </section>

          <section className="flex flex-col flex-wrap gap-4">
            <h2 className="text-3xl font-medium py-4 text-center">Security</h2>
            <p>
              The security of your data is important to us, but remember that no
              method of transmission over the Internet, or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your data, we cannot guarantee its
              absolute security.
            </p>
          </section>

          <section className="flex flex-col flex-wrap gap-4">
            <h2 className="text-3xl font-medium py-4 text-center">
              Your Choices
            </h2>
            <p>
              You have a right to access, update or delete your personal
              information. You can also object to the processing of your
              personal data, and request the restriction of processing your
              personal data. To exercise these rights, please contact us through
              the contact information provided below.
            </p>
          </section>

          <section className="flex flex-col flex-wrap gap-4">
            <h2 className="text-3xl font-medium py-4 text-center">
              Contact Us
            </h2>
            <p>
              If you have any questions about this Privacy Policy, please feel
              free to contact us through{" "}
              <span className="cursor-pointer">team@orazweb.com</span>
            </p>
          </section>
        </section>
      </div>
    </div>
  );
}

export default PrivacyPol;
