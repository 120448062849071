import React from "react";
import webDev from "../../Assets/images/web.webp";
import webDesign from "../../Assets/images/webdesign.webp";
import appDev from "../../Assets/images/app-development.webp";
import plan from "../../Assets/images/plan.webp";
import design from "../../Assets/images/design.webp";
import develope from "../../Assets/images/code.webp";
import deploy from "../../Assets/images/deployment.webp";
import digital from "../../Assets/images/social-media.webp";
import Seo from "../Seo/Seo";

function Services() {
  const seoData = {
    title:
      "Orazweb Solutions: Web Design, Development & App Development Services",
    description:
      "Orazweb Solutions offers web design, development, and app development services to cater to all your digital needs. We craft exceptional websites and cutting-edge mobile apps that elevate your online presence and drive results.",
    keywords:
      "web design, web development, app development, mobile app, digital marketing, SEO",
  };

  return (
    <section id="services">
      <div className="w-full h-full">
        <Seo {...seoData} />

        <div className=" mx-auto text-center px-10 py-20">
          <h2 className="text-4xl lg:text-6xl py-4 font-bold">
            Services We Offer
          </h2>
          <p className="mt-6 font-Roboto text-[#34454c] md:text-2xl lg:text-xl text-2xl">
            At Orazweb, we specialize in web development, app development, and
            web designing to cater to all your digital needs.
          </p>
        </div>
        {/* card section */}
        <div className="mx-auto px-10 py-10 flex flex-wrap gap-6 justify-evenly transition-all duration-300 ease-in">
          {/* card 1 */}

          <div className="border border-gray-400 rounded-md flex flex-col items-center max-w-72 px-4 py-8 text-center hover:-translate-y-3 transition-all duration-500 ease-in-out hover:bg-green-50">
            <img
              src={webDev}
              alt="webdev icon"
              className="h-20 w-20 object-cover"
              loading="lazy"
            />
            <h3 className="text-2xl font-bold leading-10 my-4">
              Web Development
            </h3>
            <p className="my-4 font-Roboto text-[#34454c] text-xl">
              Developing pixel perfect websites that elevate your online
              presence and drive results.
            </p>
          </div>
          {/* card 2 */}
          <div className="border border-gray-400 rounded-md flex flex-col items-center max-w-72 px-4 py-8 text-center hover:-translate-y-3 transition-all duration-500 ease-in-out hover:bg-green-50">
            <img
              src={appDev}
              alt="appDev icon"
              className="h-20 w-20 object-cover"
              loading="lazy"
            />
            <h3 className="text-2xl font-bold leading-10 my-4">
              App Development
            </h3>
            <p className="my-4 font-Roboto text-[#34454c] text-xl">
              Experience innovation in the palm of your hand with Orazweb's
              cutting-edge mobile app development solutions.
            </p>
          </div>
          {/* card 3 */}
          <div className="border border-gray-400 rounded-md flex flex-col items-center max-w-72 px-4 py-8 text-center hover:-translate-y-3 transition-all duration-500 ease-in-out hover:bg-green-50">
            <img
              src={webDesign}
              alt="webdesign icon"
              className="h-20 w-20 object-cover"
              loading="lazy"
            />
            <h3 className="text-2xl font-bold leading-10 my-4">UI/UX</h3>
            <p className="my-4 font-Roboto text-[#34454c] text-xl">
              At Orazweb, we design captivating digital experiences that leave a
              lasting impression on your audience.
            </p>
          </div>
          {/* card 4 */}
          <div className="border border-gray-400 rounded-md flex flex-col items-center max-w-72 px-4 py-8 text-center hover:-translate-y-3 transition-all duration-500 ease-in-out hover:bg-green-50">
            <img
              src={digital}
              alt="webdesign icon"
              className="h-20 w-20 object-cover"
              loading="lazy"
            />
            <h3 className="text-2xl font-bold leading-10 my-4">
              Digital Marketing
            </h3>
            <p className="my-4 font-Roboto text-[#34454c] text-xl">
              Amplify your brand's voice and boost your sales with Orazweb's
              expert data-driven digital marketing strategies.
            </p>
          </div>
        </div>

        {/* border line */}
        <div className="border w-11/12 mx-auto mt-4"></div>

        {/* how we work part */}
        <div className="mx-auto px-10 py-10 flex flex-col flex-wrap justify-center items-center">
          <p className="text-4xl lg:text-5xl py-4 font-medium">How We Work</p>

          <div className="flex flex-col md:flex-row w-full justify-around items-center flex-wrap mt-6">
            {/* planing */}
            <div className="text-center px-4 py-6 flex flex-col items-center">
              <img
                src={plan}
                alt="planing logo"
                className="max-h-32 max-w-32 md:max-h-24 md:max-w-24 object-contain"
                loading="lazy"
              />
              <p className="py-4 text-2xl md:text-3xl lg:text-xl font-semibold mt-4">
                Plan
              </p>
            </div>

            {/* designing */}

            <div className=" text-center px-4 py-6 flex flex-col items-center">
              <img
                src={design}
                alt="designing logo"
                className="max-h-32 max-w-32 md:max-h-24 md:max-w-24 object-contain"
                loading="lazy"
              />
              <p className="py-4 text-2xl md:text-3xl lg:text-xl font-semibold mt-4">
                Design
              </p>
            </div>

            {/* developing */}

            <div className=" text-center px-4 py-6 flex flex-col items-center">
              <img
                src={develope}
                alt="coding logo"
                className="max-h-32 max-w-32 md:max-h-24 md:max-w-24 object-contain"
                loading="lazy"
              />
              <p className="py-4 text-2xl md:text-3xl lg:text-xl font-semibold mt-4">
                Develope
              </p>
            </div>

            {/* deploying */}

            <div className="text-center px-4 py-6 flex flex-col items-center">
              <img
                src={deploy}
                alt="deploy logo"
                className="max-h-32 max-w-32 md:max-h-24 md:max-w-24 object-contain"
                loading="lazy"
              />
              <p className="py-4 text-2xl md:text-3xl lg:text-xl font-semibold mt-4">
                Deploy
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
