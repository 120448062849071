import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import logo from "../../Assets/images/logo.webp";
// import { RxHamburgerMenu } from "react-icons/rx";
// import { GrClose } from "react-icons/gr";
// , { useState, useEffect }

// function Navbar() {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Assuming 768px is your mobile breakpoint

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   const closeNav = () => {
//     if (isOpen === true) {
//       setIsOpen(!isOpen);
//     }
//   };

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//       if (!isMobile) {
//         setIsOpen(false);
//       }
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, [isMobile]);

//   return (
//     <nav className="w-full flex items-center bg-white fixed top-0 left-0 right-0 z-50">
//       <div className="w-11/12 md:w-10/12 mx-auto flex justify-between items-center py-4">
//         {/* logo section */}
//         <ScrollLink
//           to="home"
//           smooth={true}
//           duration={500}
//           className="hover:cursor-pointer"
//         >
//           <Link to="/">
//             <img
//               src={logo}
//               alt="Orazweb"
//               className="w-15 h-10 bg-cover"
//               loading="lazy"
//             />
//           </Link>
//         </ScrollLink>
//         <ul className="hidden lg:flex space-x-6 cursor-pointer text-xl">
//           <li className="group p-2">
//             <ScrollLink to="home" smooth={true} duration={500} className="">
//               <Link to="/">HOME</Link>
//             </ScrollLink>
//             <div className="border-[1px] invisible transition-all duration-200 opacity-0 group-hover:visible group-hover:opacity-100 border-green-500"></div>
//           </li>
//           <li className="group p-2">
//             <ScrollLink to="about" smooth={true} duration={500} className="">
//               <Link to="/">ABOUT</Link>
//             </ScrollLink>
//             <div className="border-[1px] invisible transition-all duration-200 opacity-0 group-hover:visible group-hover:opacity-100 border-green-500"></div>
//           </li>
//           <li className="group p-2">
//             <ScrollLink to="services" smooth={true} duration={500} className="">
//               <Link to="/"> SERVICES</Link>
//             </ScrollLink>
//             <div className="border-[1px] invisible transition-all duration-200 opacity-0 group-hover:visible group-hover:opacity-100 border-green-500"></div>
//           </li>
//           <li className="group p-2">
//             <ScrollLink
//               to="technologies"
//               smooth={true}
//               duration={500}
//               className=""
//             >
//               <Link to="/"> TECHNOLOGIES</Link>
//             </ScrollLink>
//             <div className="border-[1px] invisible transition-all duration-200 opacity-0 group-hover:visible group-hover:opacity-100 border-green-500"></div>
//           </li>
//           <li className="group p-2">
//             <ScrollLink
//               to="contactUs"
//               smooth={true}
//               duration={500}
//               className=""
//             >
//               <Link to="/">CONTACT US</Link>
//             </ScrollLink>
//             <div className="border-[1px] invisible transition-all duration-200 opacity-0 group-hover:visible group-hover:opacity-100 border-green-500"></div>
//           </li>
//         </ul>
//         <div className="lg:hidden mr-10">
//           <button onClick={toggleMenu}>
//             {isOpen ? <GrClose /> : <RxHamburgerMenu />}
//           </button>
//         </div>
//       </div>

//       {isOpen && (
//         <div
//           className={`fixed top-0 left-0 w-full h-full z-50 bg-white py-2 px-4 mt-16 overflow-auto shadow-lg transition duration-300 ease-in-out ${
//             isOpen ? "translate-y-0" : "translate-y-full"
//           } text-center`}
//         >
//           <ul className="flex flex-col space-y-6 cursor-pointer text-xl">
//             <li className="group p-2">
//               <ScrollLink
//                 to="home"
//                 smooth={true}
//                 duration={500}
//                 onClick={closeNav}
//               >
//                 <Link to="/">HOME</Link>
//               </ScrollLink>
//             </li>
//             <li className="group p-2">
//               <ScrollLink
//                 to="about"
//                 smooth={true}
//                 duration={500}
//                 onClick={closeNav}
//               >
//                 <Link to="/">ABOUT</Link>
//               </ScrollLink>
//             </li>
//             <li className="group p-2">
//               <ScrollLink
//                 to="services"
//                 smooth={true}
//                 duration={500}
//                 onClick={closeNav}
//               >
//                 <Link to="/"> SERVICES</Link>
//               </ScrollLink>
//             </li>
//             <li className="group p-2">
//               <ScrollLink
//                 to="technologies"
//                 smooth={true}
//                 duration={500}
//                 onClick={closeNav}
//               >
//                 <Link to="/">TECHNOLOGIES</Link>
//               </ScrollLink>
//             </li>
//             <li className="group p-2">
//               <ScrollLink
//                 to="contactUs"
//                 smooth={true}
//                 duration={500}
//                 onClick={closeNav}
//               >
//                 <Link to="/">CONTACT US</Link>
//               </ScrollLink>
//             </li>
//           </ul>
//         </div>
//       )}
//     </nav>
//   );
// }

// export default Navbar;

function Navbar() {
  return (
    <header className="bg-background px-4 py-3 sm:px-6 lg:px-8 flex items-center justify-between">
      <Link to="/" className="flex items-center gap-2" prefetch={false}>
        <img src={logo} className="w-15 h-10" alt="logo"/>
      </Link>
      <nav className="hidden lg:flex items-center gap-6 text-xl">
        <ScrollLink to="home" smooth={true} duration={500} className="">
          <Link
            to="/"
            className="font-medium hover:underline underline-offset-4 transition-all duration-200"
            prefetch={false}
          >
            Home
          </Link>
        </ScrollLink>
        <ScrollLink to="about" smooth={true} duration={500} className="">
          <Link
            to="/"
            className=" font-medium hover:underline underline-offset-4"
            prefetch={false}
          >
            About
          </Link>
        </ScrollLink>
        <ScrollLink to="services" smooth={true} duration={500} className="">
          <Link
            to="/"
            className="font-medium hover:underline underline-offset-4"
            prefetch={false}
          >
            Services
          </Link>
        </ScrollLink>

        <ScrollLink to="technologies" smooth={true} duration={500} className="">
          <Link
            to="/"
            className="font-medium hover:underline underline-offset-4"
            prefetch={false}
          >
            Technologies
          </Link>
        </ScrollLink>
        {/* <ScrollLink to="contactUs" smooth={true} duration={500} className="">
          <Link
            href="#"
            className="font-medium hover:underline underline-offset-4"
            prefetch={false}
          >
            Contact
          </Link>
        </ScrollLink> */}
      </nav>
      {/* <ScrollLink to="contactUs" smooth={true} duration={500} className="">
        <button className="border bg-[#171717] text-white text-xl leading-6 w-32 h-10 rounded-md">
          Get a Quote
        </button>
      </ScrollLink> */}
    </header>
  );
}

export default Navbar;
