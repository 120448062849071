import React from "react";
// import "./HeroSection.css";
import { Link as ScrollLink } from "react-scroll";
import heroImage from "../../Assets/images/heroSection.webp";
// import RunningText from "./RunningText";
import Seo from "../Seo/Seo";

function HeroSection() {
  const seoData = {
    title: "Orazweb Solutions: Tech Innovation for Your Success",
    description:
      "Orazweb Solutions: A tech company transforming businesses through innovative web design, development, and app creation. We turn your dreams into reality.",
    keywords:
      "web design, web development, app development, mobile app, innovation, technology solutions",
  };

  // return (
  //   <section className="h-full w-full">
  //     <Seo {...seoData} />
  //     <div className="w-11/12 md:w-10/12 h-full mx-auto flex flex-col-reverse md:flex-row py-10 items-center lg:relative">
  //       {/* text section */}
  //       <header className="w-full flex flex-col justify-center text-left gap-y-4 lg:absolute z-30">
  //         <div className="">
  //           <h2 className="text-4xl md:text-4xl lg:text-6xl py-4 font-extrabold leading-10">
  //             Orazweb Solutions
  //           </h2>

  //           <h1 className="text-3xl font-normal leading-10 text-[#34454c]">
  //             Tech company focused on innovation
  //             <br />
  //             to turn your dreams into reality.
  //           </h1>
  //         </div>
  //         <div className="flex gap-x-2 items-baseline">
  //           <h3 className="text-2xl font-bold leading-10 py-4">
  //             Make your business
  //           </h3>
  //           <RunningText />
  //         </div>
  //       </header>

  //       {/* image section */}
  //       <article className="w-full h-full md:w-1/2 md:ml-auto relative flex justify-center items-center">
  //         {/* New: Semi-transparent overlay */}
  //         <div className="md:absolute inset-0 bg-white opacity-5 z-0"></div>
  //         {/* Image with explicit size */}
  //         <img
  //           src={heroImage}
  //           alt="Hero section"
  //           className="z-10"
  //           width="600" // Set appropriate width
  //           height="400" // Set appropriate height
  //           loading="eager" // Avoid lazy-loading above-the-fold images
  //         />
  //       </article>
  //     </div>
  //   </section>
  // );

  // new hero section
  return (
    
      <section className="bg-background py-12 md:py-24 lg:py-32">
      <Seo {...seoData} />
        <div className="container px-4 md:px-6 grid md:grid-cols-2 gap-8 items-center">
          <div className="space-y-4">
            <h1 className="text-5xl font-bold tracking-tighter sm:text-6xl md:text-7xl">
              Elevate Your Online Presence with Oraz Web Solution
            </h1>
            <p className="text-muted-foreground md:text-xl">
              We're a passionate web dev and design dream team, obsessed with
              your online growth.{" "}
            </p>
            <div className="flex gap-4">
              {/* <button className="border bg-[#171717] text-white text-xl leading-6 w-32 h-10 rounded-md">
                Learn More
              </button> */}
              <ScrollLink
                to="contactUs"
                smooth={true}
                duration={500}
                className=""
              >
                <button className="border bg-[#171717] text-white text-xl leading-6 w-32 h-10 rounded-md">
                  Get a Quote
                </button>
              </ScrollLink>
            </div>
          </div>
          <img
            src={heroImage}
            width="600"
            height="533"
            alt="Hero"
            className="mx-auto aspect-[5/4] rounded-xl object-cover"
          />
        </div>
      </section>
  
  );
}

export default HeroSection;
