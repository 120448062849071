import React from "react";
import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  // use ref variables to record response
  const [fName, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_PUBLIC_KEY);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const serviceId = process.env.REACT_APP_SERVICE_ID;
    const templateId = process.env.REACT_APP_TEMPLATE_ID;

    if (!fName || !email || !phoneNumber || !message) {
      toast.error("Fields cannot be empty", { position: "top-center" });
      return;
    }
    if (phoneNumber.length < 10) {
      toast.error("Enter a valid Mobile no", { position: "top-center" });
      return;
    }

    try {
      await emailjs.send(serviceId, templateId, {
        to_name: "orazweb06@gmail.com",
        from_name: `${fName} ${lastName}`,
        from_email: email,
        contact_num: phoneNumber,
        message: message,
      });

      // Reset form fields after submission

      setName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
      setMessage("");

      // response
      console.log("email sent");
      toast.success("Email sent", { position: "top-center" });
    } catch (err) {
      console.log("error while sneding mail", err);
      toast.error("Sorry fault at our end", { position: "top-center" });
    }
  };

  return (
    <section className="h-full w-full">
      {/* form heading */}
      <div className="w-11/12 md:w-10/12 mx-auto text-center px-10 py-20">
        <h3 className="text-4xl lg:text-6xl py-4 font-bold">Get In Touch</h3>
        <p className="mt-6 font-Roboto text-[#34454c] md:text-2xl lg:text-xl text-2xl">
          Please fill the below form and our team will get back to you
        </p>
      </div>

      {/* form input section */}

      <div className=" w-11/12 md:w-10/12 mx-auto px-10 py-20">
        <form method="POST" onSubmit={handleSubmit}>
          {/* <div className="flex flex-col">
            <div className="flex flex-wrap justify-between">
              <input
                type="text"
                placeholder="First name"
                className="w-full md:w-5/12 px-4 py-2 my-4 border-b border-black text-xl"
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Last name"
                className="w-full md:w-5/12 px-4 py-2 my-4 outline-none border-b border-black text-xl"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <input
              type="text"
              placeholder="Email"
              className="w-full px-4 py-2 my-4 outline-none border-b border-black text-xl"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Contact number"
              className="w-full px-4 py-2 my-4 outline-none border-b border-black text-xl"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <textarea
              placeholder="What do you need to know?"
              name="quiery"
              rows={4}
              className="w-full px-4 py-2 my-4 outline-none border-b border-black text-xl"
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="flex ">
            <button type="submit" className="border bg-[#171717] text-white text-xl leading-6 w-32 h-10 rounded-md">
          Submit
        </button>
          </div> */}

          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div>
              <label
                htmlFor="first-name"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                First name
              </label>
              <div className="mt-2.5">
                <input
                  id="first-name"
                  name="first-name"
                  type="text"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="last-name"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Last name
              </label>
              <div className="mt-2.5">
                <input
                  id="last-name"
                  name="last-name"
                  type="text"
                  autoComplete="family-name"
                  onChange={(e) => setLastName(e.target.value)}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Email
              </label>
              <div className="mt-2.5">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="phone-number"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Phone number
              </label>
              <div className=" mt-2.5">
                <input
                  id="phone-number"
                  name="phone-number"
                  type="tel"
                  autoComplete="tel"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Message
              </label>
              <div className="mt-2.5">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                  defaultValue={""}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="mt-10">
            <button
              type="submit"
              className="border bg-[#171717] text-white text-xl leading-6 w-32 h-10 rounded-md"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Contact;
