import React from "react";
import "./Home.css";
import { Element } from "react-scroll";
import HeroSection from "../../Components/HeroSection/HeroSection";
import About from "../../Components/AboutSection/About";
import Services from "../../Components/Services/Services";
import Technology from "../../Components/Technologies/Technology";
import Contact from "../../Components/Contact/Contact";
function Home() {
  return (
    <div className="flex flex-col min-h-[100dvh] ">
      <main className="flex-1 ">
        <Element name="home">
          <HeroSection />
        </Element>
        <Element name="about">
          <About />
        </Element>
        <Element name="services">
          <Services />
        </Element>
        <Element name="technologies">
          <Technology />
        </Element>
        <Element name="contactUs">
          <Contact />
        </Element>
      </main>
    </div>
  );
}

export default Home;
